@use "global" as *;

.p-footer {
    padding: rem(150) rem(56) rem(50) rem(50);
    position: relative;

    @include mq('md') {
        padding: 0 0 rem(20) 0;
        margin-top: rem(50);
    }

    &::after {
        content: "";
        display: block;
        width: rem(442.8);
        height: rem(442.8);
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        background: $red;

        @include mq('md') {
            width: rem(167);
            height: rem(167);
        }
    }
}

.p-footer__inner {
    position: relative;

    @include mq('md') {
        padding-left: rem(18);
        padding-right: rem(18);
        max-width: rem(460);
        margin: 0 auto;
    }
}

.p-footer__nav {
    max-width: rem(645);
}
.p-footer__nav ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: rem(15);

    @include mq('md') {
        grid-template-columns: repeat(2, 1fr);
        column-gap: rem(20);
    }
}

.p-footer__nav-list a {
    padding: rem(20) 0;
    font-size: rem(12);
    font-weight: 500;
    line-height: calc(35 / 12);
    letter-spacing: 0.05em;
    min-width: rem(150);
    border-top: 1px solid #F8CFCF;

    @include mq('md') {
        min-width: rem(160);
    }
}

address {
    font-style: normal;
    margin-top: rem(40);

    @include mq('md') {
        margin-top: rem(15);
    }
}
.p-footer__add {
    font-size: rem(13);
    font-weight: 500;
    line-height: calc(18 / 13);

}
.p-footer__tel, .p-footer__fax {
    font-size: rem(16);
    line-height: calc(30 / 16);
    letter-spacing: 0.08em;
    font-family: $font-en;
    display: inline-block;
    margin-top: rem(13);

    @include mq('md') {
        display: block;
    }
}
.p-footer__tel {
    @include mq('md') {
        margin-top: rem(15);
    }
}
.p-footer__fax {
    margin-left: rem(15);

    @include mq('md') {
        margin-left: 0;
        margin-top: 0;
    }
}

.p-footer__privacy {

    @include mq('md') {
        margin-top: rem(15);
    }
}

.p-footer__privacy a {
    display: inline-block;
}

.p-footer__privacy a img {
    width: rem(330);
    height: auto;

    @include mq('md') {
        width: rem(260);
    }
}

.p-footer__copyright {
    font-size: rem(12);
    line-height: calc(24 / 12);
    letter-spacing: 0.08em;
    font-family: $font-en;

    @include mq('md') {
        margin-top: rem(15);
        line-height: calc(18 /12);
    }
}

.p-footer__logo {
    position: absolute;
    bottom: rem(63);
    right: rem(56);

    @include mq('md') {
        right: rem(21);
        bottom: rem(24);
    }
}
.p-footer__logo img {
    width: rem(167);

    @include mq('md') {
        width: rem(63);
    }
}