@use "global" as *;

.p-recruit {

}

.p-recruit__inner {
    padding-left: rem(50);
    padding-right: rem(50);
    position: relative;

    &::before {
        content: "";
        width: rem(3722);
        height: rem(3722);
        bottom: rem(-1392);
        right: calc(50% + 300px);
        background: url(../images/section-bg@2x.png) no-repeat center / cover;
        z-index: -1;
        position: absolute;

        @include mq('lg') {
            right: calc(50% + 220px);
        }

        @include mq('md') {
            width: rem(1188);
            height: rem(1188);
            bottom: rem(240);
            right: calc(50% + 70px);
            background: url(../images/sec-bg-sp@2x.png) no-repeat center / cover;
        }
    }

    @include mq('md') {
        padding-left: 0;
        padding-right: 0;
        max-width: rem(550);
        margin: 0 auto;
    }
}


.p-recruit__img {

}

.p-recruit__img img {
    width: 100%;
    height: auto;
    filter: drop-shadow(rem(10) rem(10) 0 rgba(0, 0, 0, 0.16));
    will-change: filter;
}

.p-recruit__body {
    display: flex;
    margin-top: rem(10);
    padding-top: rem(110);

    @include mq('md') {
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: rem(-64);
        padding-top: rem(90);
        position: relative;
    }
}

.p-recruit__title-box {
    padding-top: rem(90);

    @include mq('md') {
        position: absolute;
        top: rem(112);
        left: rem(20);
    }
}

.p-recruit__lead {
    font-size: rem(48);
    line-height: calc(90 / 48);
    writing-mode: vertical-rl;
    margin-left: auto;

    @include mq('md') {
        font-size: rem(32);
        line-height: calc(55 / 32);
    }
}

.p-recruit__content {
    margin-left: rem(80);
    margin-right: rem(50);
    @include mq('md') {
        margin-left: 0;
        margin-right: 0;
    }
}

.p-recruit__text {
    font-size: rem(16);
    line-height: calc(40 / 16);

    @include mq('md') {
        font-size: rem(15);
        line-height: calc(28 / 15);
        margin-top: rem(20);
    }
}

.p-recruit__occupation-list {

}

.p-recruit__sub-title {
    font-size: rem(20);
    font-weight: 500;
    line-height: calc(41 / 20);
    padding-top: rem(40);
    padding-bottom: rem(30);
    border-bottom: rem(3) solid $red;

    @include mq('md') {
        padding-top: rem(26);
        padding-bottom: rem(20);
    }
}

.p-recruit__btn a {
    padding: rem(25) 0;
    font-size: rem(15);
    font-weight: 500;
    line-height: calc(41 / 15);
    border-bottom: 1px solid $red;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq('md') {
        padding: rem(34) 0;
    }
}

.p-recruit__btn--item {
    margin-left: auto;
}

.p-recruit__title-sp {
    @include mq('md') {
        color: $white;
        background: $red;
    }
}
.p-recruit__en-title-sp {
    @include mq('md') {
        color: $red;

        &::before {
            content: "";
            display: inline-block;
            width: rem(22);
            height: rem(17);
            margin-right: rem(7);
            background: url(../images/logo-min.svg) no-repeat center / cover;
            vertical-align: middle;
        }
    }
}