@use "global" as *;

.p-company {
    padding-top: rem(220);
    padding-bottom: rem(220);

    @include mq('md') {
        padding-top: rem(90);
        margin-top: rem(-40);
        padding-bottom: rem(50);
    }
}

.p-company__inner {
    padding-left: rem(50);
    display: flex;
    align-items: center;
    position: relative;

    &::before {
        content: "";
        width: rem(3722);
        height: rem(3722);
        bottom: rem(-1618);
        left: calc(50% + 300px);
        background: url(../images/section-bg@2x.png) no-repeat center / cover;
        z-index: -1;
        position: absolute;

        @include mq('lg') {
            left: calc(50% + 225px);
        }

        @include mq('md') {
            background: none;
        }
    }

    @include mq('md') {
        padding-left: rem(18);
        flex-direction: column;
        align-items: start;
        position: relative;
        max-width: rem(500);
    }

}

.p-company__title-box {

}


.p-company__info {
    margin-left: rem(62);
    display: grid;
    grid-template-columns: rem(120) rem(480);

    @include mq('md') {
        margin-top: rem(20);
        margin-left: auto;
        grid-template-columns: rem(85) rem(255);
    }
}

.p-company__title {
    font-size: rem(15);
    font-weight: 700;
    line-height: calc(41 / 15);
    padding: rem(20);
    border-bottom: 1px solid $red;

    &:first-child {
        border-top: 1px solid $red;
    }

    @include mq('md') {
        padding: rem(10) 0;
        line-height: 2;
    }
}

.p-company__desc {
    font-size: rem(15);
    line-height: calc(41 / 15);
    padding: rem(20);
    border-bottom: 1px solid #F8CFCF;

    &:nth-of-type(1) {
        border-top: 1px solid #F8CFCF;
    }

    @include mq('md') {
        padding: rem(10);
        line-height: 2;
    }
}

.p-company__logo {
    margin-left: auto;

    @include mq('md') {
        position: absolute;
        top: rem(20);
        right: rem(18);
    }
}
.p-company__logo img {
    width: rem(177);
    height: auto;

    @include mq('md') {
        width: rem(90);
    }
}

