@use "global" as *;

.p-MV {
    overflow: hidden;
    position: relative;
    padding-top: rem(180);

    &::before {
        content: "";
        width: vw(1366, 2395);
        height: vw(1366, 2395);
        bottom: rem(328);
        right: vw(1366, -300);
        background: url(../images/bg-red.png) no-repeat center / cover;
        position: absolute;

        @include mq('lg') {
            bottom: rem(544);
            right: vw(1366, -280);
        }

        @include mq('md') {
            background: none;
        }
    }

    @include mq('md') {
        position: relative;
        padding-top: rem(94);
    }
}

.p-MV__inner {
    padding-left: rem(50);
    padding-right: rem(50);
    display: flex;
    align-items: center;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        display: block;
        bottom: rem(530);
        right: rem(60);
        width: rem(1780);
        height: rem(1780);
        z-index: -1;

        @include mq('md') {
            width: vw(375, 1131);
            height: vw(375, 1131);
            bottom: rem(120);
            right: vw(375, -300);
            background: url(../images/bg-red.png) no-repeat center / cover;
            position: absolute;
        }
    }
    @include mq('md') {
        flex-direction: column;
        padding-left: rem(18);
        padding-right: rem(18);
    }
}
.p-MV__body {
    width: calc(710 / 1100 * 100%);

    @include mq('md') {
        width: 100%;
    }
}

.p-MV__title {
    font-size: rem(52);
    font-weight: 400;
    line-height: calc(86 / 52);
    letter-spacing: 0.15em;
    color: $white;

    @include mq('md') {
        font-size: rem(30);
        line-height: calc(44 / 30);
    }
}

.p-MV__lead {
    font-size: rem(28);
    font-weight: 300;
    line-height: calc(100 / 38);
    letter-spacing: 0.08em;
    color: $white;

    @include mq('md') {
        font-size: rem(15);
        font-weight: 400;
        line-height: calc(58 / 18);
        letter-spacing: normal;
        overflow-wrap: break-word;
    }
}

.p-MV__lead span {
    font-size: rem(38);
    font-weight: 300;
    line-height: calc(100 / 38);
    letter-spacing: 0.08em;
    font-family: $font-en;
    text-transform: uppercase;
    color: $white;

    @include mq('md') {
        font-size: rem(18);
        font-weight: 400;
        line-height: calc(58 / 18);
    }
}

.p-MV__text {
    font-size: rem(14);
    font-weight: 500;
    line-height: calc(34 / 14);
    letter-spacing: 0.04em;
    color: $white;

    @include mq('md') {
        font-size: rem(13);
        line-height: calc(27 / 13);
    }
}

.p-MV__logo {
    width: calc(390 / 1100 * 100%);
    margin-left: rem(10);
    margin-bottom: rem(90);
    @include mq('md') {
        width: 100%;
        margin-top: vw(375,140);
        margin-left: 0;
        margin-bottom: 0;
    }
}
.p-MV__logo img {
    width: rem(308);
    height: auto;
    @include mq('md') {
        margin-left: auto;
        width: vw(375, 184);
        height: auto;
    }
}

.p-MV__inner2 {
    margin-top: rem(150);
    position: relative;
    background: url(../images/MV@2x.png) no-repeat center /contain;
    @include mq('md') {
        background: none;
        margin-top: 0;
        max-width: rem(500);
    }

}

.P-MV__content {
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include mq('md') {
        flex-direction: column-reverse;
    }
}
.p-MV__body2 {
    width: calc(622 / 1200 * 100%);
    margin-left: rem(26);
    @include mq('md') {
        margin-top: rem(50);
        padding-top: 0;
        background: url(../images/MV@2x.png) no-repeat center /cover;
        border-radius: rem(40);
        width: 100%;
        margin-left: 0;
        padding: rem(50) 0 rem(200);
    }
}

.p-MV__example {
    font-size: rem(16);
    font-weight: 500;
    line-height: calc(44 / 16);
    letter-spacing: 0.04em;
    color: $white;

    @include mq('md') {
        padding-left: rem(53);
        font-size: rem(15);
        line-height: calc(32 / 15);
    }

    &::before {
        content: "";
        display: inline-block;
        width: rem(31);
        height: rem(24);
        margin-right: rem(10);
        background: url(../images/logo-min.svg) no-repeat center / cover;
        vertical-align: middle;

        @include mq('md') {
            width: rem(25);
            height: rem(20);
        }
    }
}

.p-MV__example-title {
    margin-top: rem(30);
    font-size: rem(50);
    font-weight: 300;
    line-height: calc(87 / 50);
    letter-spacing: 0.15em;
    color: $white;

    @include mq('md') {
        margin-top: rem(20);
        padding: 0 rem(48);
        font-size: rem(26);
        line-height: calc(49 / 26);
    }
}

.p-MV__example-text {
    margin-top: rem(30);
    font-size: rem(16);
    font-weight: 500;
    line-height: calc(44/ 16);
    letter-spacing: 0.04em;
    color: $white;

    @include mq('md') {
        margin-top: rem(20);
        padding: 0 rem(30);
        font-size: rem(15);
        line-height: calc(32 / 15);
    }
}
.p-MV__img {
    position: relative;
    z-index: 2;
    width: calc(578 / 1200 * 100%);
    @include mq('md') {
        margin-top: rem(-200);
        width: 100%;
    }
}

.p-MV__img  img {
    aspect-ratio: 992 / 1444;
    object-fit: contain;
    width: 100%;
    @include mq('md') {
        aspect-ratio:  240 / 390;
        width: rem(240);
        margin: 0 auto;
        height: 100%;
    }
}

.p-MV__copyright {
    font-size: 10px;
    line-height: calc(24 / 10);
    letter-spacing: 0.08em;
    color: $white;
    writing-mode: vertical-rl;
    text-transform: capitalize;
    position: absolute;
    top: rem(325);
    left: rem(25);

    @include mq('lg') {
        left: rem(18);
    }

    @include mq('md') {
        top: rem(150);
        left: 0;
        right: rem(3);
    }
}