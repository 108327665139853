@use "global" as *;

.p-service {
    padding-top: rem(100);
    @include mq('md') {
        padding-top: rem(90);
    }
}

.p-service__inner {
    padding-left: rem(125);
    padding-right: rem(125);
    position: relative;

    &::before {
        content: "";
        width: rem(3722);
        height: rem(3722);
        bottom: rem(-1248);
        right: calc(50% + 310px);
        background: url(../images/section-bg@2x.png) no-repeat center / cover;
        z-index: -1;
        position: absolute;

        @include mq('lg') {
            right: calc(50% + 260px);
        }

        @include mq('md') {
            width: rem(1188);
            height: rem(1188);
            bottom: rem(410);
            right: calc(50% + 70px);
            background: url(../images/sec-bg-sp@2x.png) no-repeat center / cover;
        }
    }

    @include mq('md') {
        padding-left: 0;
        padding-right: 0;
        max-width: rem(340);
    }
}

.p-service__title-box {
    text-align: right;
}

.p-service__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: rem(50);
    margin-top: rem(30);

    @include mq('md') {
        display: block;
        margin-top: 0;
    }
}
.p-service__card:nth-of-type(1) {
    margin-top: rem(-90);
    margin-bottom: rem(90);
    @include mq('md') {
        margin-top: rem(20);
        margin-bottom: 0;
    }
}

.p-service__card:nth-of-type(2) {
    @include mq('md') {
        margin-top: rem(20);
    }
}



