@use "global" as *;

.p-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: rem(180);
  z-index: 3;

  @include mq('md') {
    position: fixed;
    background: $red;
    height: rem(90);
  }
}

.p-header__inner {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-header__logo a img {
  width: rem(88.72);
  height: auto;

  @include mq('md') {
    width: rem(77.93);
  }
}

.p-header__hamburger {
  display: none;

  @include mq('md') {
    display: block;
    position: fixed;
    top: rem(40);
    right: rem(17);
    z-index: 1000;
    cursor: pointer;
    width: rem(30);
    height: rem(15);
  }
}
/*×に変化*/
.p-header__hamburger span {
  @include mq('md') {
    display: inline-block;
    position: absolute;
    right: 0;
    width: rem(30);
    height: 2px;
    background-color: $white;
    transition: all 0.5s;
  }
}
.p-header__hamburger span:nth-of-type(1) {
  top: 0;
}

.p-header__hamburger span:nth-of-type(2) {
  top: rem(11);
}

.p-header__hamburger.active span:nth-of-type(1) {
  top: rem(24);
  right: rem(40);
  width: rem(30);
  background-color: $white;
  transform: translateY(#{rem(6)}) rotate(-45deg);
}

.p-header__hamburger.active span:nth-of-type(2) {
  top: rem(36);
  right: rem(40);
  width: rem(30);
  background-color: $white;
  transform: translateY(#{rem(-6)}) rotate(45deg);
}

.p-header__hamburger span.js-headerColorScroll {
  background-color: $red;
}



.p-header__nav {
  @include mq('md') {
    display: none;
    transition: all 0.3s;
    position: fixed;
    top: rem(24);
    left: rem(18);
    right: rem(18);
    height: 100dvh;
    z-index: 999;
  }
}


.p-header__nav-inner {
  display: flex;
  justify-content: space-between;

  @include mq('md') {
    display: block;
    text-align: center;
    padding: rem(48) rem(24);
    background: $red;
    border-radius: rem(40);
  }
}

.p-header__nav-list {
  display: flex;
  padding-top: rem(40);

  @include mq('md') {
    display: block;
    padding-top: 0;
  }
}

.p-header__nav-item a {
  display: block;
  color: $white;
  padding: rem(15);
  text-align: center;
  font-size: rem(15);
  font-weight: 500;
  line-height: calc(30 / 15);

  @include mq('md') {
    display: inline-block;
    font-size: rem(18);
    line-height: calc(30 / 18);
    padding: rem(10);
  }

  &::after {
    content: attr(data-en);
    display: block;
    font-size: rem(12);
    font-weight: 700;
    line-height: calc(24 / 12);
    letter-spacing: 0.08em;
    font-family: $font-en;
    text-transform: uppercase;
  }
}

.p-header__btns {
  margin-left: rem(78);
  margin-top: rem(55);

  @include mq('lg') {
    margin-left: rem(50);
  }
  @include mq('md') {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(33);
    margin-left: 0;
  }
}

.p-header__btn {
  &:not(:first-child) {
    margin-top: rem(10);

    @include mq('md') {
      margin-top: 0;
      margin-left: rem(10);
    }
  }
}
.p-header__tel-btn {
  pointer-events: none;

  @include mq('md') {
    pointer-events: auto;
  }
}


.p-header__hamburger-Bg {
  display: none;
  @include mq('md') {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    z-index: 990;
    background: rgba(0, 0, 0, 0.4);
  }
}

