@use "global" as *;

.c-card {

}
.c-card__img {
}
.c-card__img img {
    display: block;
    aspect-ratio: 330 / 440;
    object-fit: cover;
    filter: drop-shadow(rem(10) rem(10) 0 rgba(0, 0, 0, 0.16));
    will-change: filter;

    @include mq('md') {
        aspect-ratio: 330 / 204;
        object-fit: contain;
    }
}
.c-card__content a {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.c-card__content--b {
    display: flex;
    align-items: center;
    margin-top: rem(35);
    justify-content: space-between;

    @include mq('md') {
        margin-top: rem(25);
        display: block;
    }
}



.c-card__body {
    margin-top: rem(20);

    @include mq('md') {
        margin-top: rem(25);
    }
}


.c-card__title {
    font-size: rem(12);
    line-height: calc( 24 / 12 );
    letter-spacing: 0.08em;
    font-family: $font-en;

    @include mq('md') {
        font-size: rem(10);
        line-height: calc( 24 / 10 );
    }
}
.c-card__title::after {
    content: counter(num, decimal-leading-zero);
}
.c-card__title span {
    padding: rem(3);
}


.c-card__lead {
    margin-top: rem(10);
    font-size: rem(15);
    font-weight: 700;
    line-height: calc(41 / 15);
}
.c-card__content--b-lead {
    margin-top: 0;
}




.c-card__btn {
    display: inline-block;
    vertical-align: middle;
    width: rem(50);
    height: rem(50);
    border-radius: 50%;
    border: 1px solid $red;
    position: relative;

    &::before,
    &::after {
        content: "";
        position: absolute;
        height: rem(1);
        right: rem(15);
        background: $red;
        transition: all 0.25s;
    }
    &::before {
        width: rem(7);
        top: calc(50% - #{rem(1)});
        transform: translateY(calc(-50% - #{rem(1)})) rotate(30deg);
    }
    &::after {
        top: 50%;
        width: rem(18);
        transform:translateY(-50%);
    }

}

.c-card2 {
    display: flex;
    flex-direction: column;
    height: 100%;

}

.c-card2__img {
    display: inline-block;
    text-align: center;
    align-items: center;
    min-height: rem(277);
    padding: rem(110) rem(53);
    background: $white;
    border-radius: rem(50);
    border: rem(0.25) solid #BFBFBF;
    box-shadow: rem(10) rem(10) 0 rgba(0, 0, 0, 0.1);

    @include mq('md') {
        min-height: rem(203);
        padding: rem(80) rem(40);
    }
}
.c-card2__img img {
    width: rem(345);

    @include mq('md') {
        width: rem(330);
    }
}

.c-card2__img--2 {
    padding: rem(100) rem(83);
    margin: 0 auto;

    @include mq('md') {
        padding: rem(73) rem(63);
    }
}

.c-card2__img--2 img {
    width: rem(283);

    @include mq('md') {
        width: rem(204);
    }
}

.c-card2__body {
    margin-top: rem(18);
}

.c-card2__title {
    font-size: rem(12);
    line-height: calc(24 / 12);
    letter-spacing: 0.08em;
    font-family: $font-en;

    @include mq('md') {
        font-size: 10px;
        line-height: calc(24 / 10);
    }
}
.c-card2__title::after {
    content: counter(num, decimal-leading-zero);
}
.c-card2__title span {
    padding: rem(3);
}

.c-card2__lead {
    margin-top: rem(10);
    font-size: rem(15);
    font-weight: 700;
    line-height: calc(41 / 15);
    letter-spacing: 0.08em;

    @include mq('md') {
        letter-spacing: 0;
        margin-top: 0;
    }
}

.c-card2__text {
    margin-top: rem(21);
    margin-bottom: rem(26);
    font-size: rem(15);
    font-weight: 500;
    line-height: calc(30 / 15);

    @include mq('md') {
        margin-top: rem(20);
        margin-bottom: rem(20);
        line-height: calc(28 / 15);
    }
}
.c-card2__btns {
    display: flex;
    flex-grow: 1;
}
.c-card2__btns--b {
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    padding-top: rem(15);
    border-top: rem(1) solid $red;
    margin-top: auto;
    text-align: center;
    width: 100%;
}

.c-card2__btns a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: rem(15);
    border-top: rem(1) solid $red;
    margin-top: auto;
    text-align: center;
    width: 100%;
}

.c-card2__btn-text {
    display: inline-block;
    font-size: rem(15);
    font-weight: 700;
    line-height: calc(30 / 15);
}
.c-card2__btn {
    margin-left: rem(20);
}


