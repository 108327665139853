@use "global" as *;

.p-business {
    padding-top: rem(200);

    @include mq('lg') {
        padding-top: rem(100);
    }
    @include mq('md') {
        padding-top: rem(90);
        margin-top: rem(-90);
    }
}

.p-business__inner {
    position: relative;
    padding-left: rem(50);
    padding-right: rem(50);

    &::before {
        content: "";
        width: rem(3722);
        height: rem(3722);
        bottom: rem(-1350);
        right: calc(50% + 300px);
        background: url(../images/section-bg@2x.png) no-repeat center / cover;
        z-index: -1;
        position: absolute;

        @include mq('lg') {
            right: calc(50% + 250px);
        }

        @include mq('md') {
            width: rem(1188);
            height: rem(1188);
            bottom: rem(440);
            right: calc(50% + 70px);
            background: url(../images/sec-bg-sp@2x.png) no-repeat center / cover;
        }
    }

    @include mq('md') {
        padding-left: rem(18);
        padding-right: rem(18);
        max-width: rem(500);
    }
}

.p-business__title-box {
    text-align: right;
}

.p-business__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: rem(50);
    margin-top: rem(55);

    @include mq('md') {
        display: block;
        margin-top: rem(20);
    }
}
.p-business__cards li:nth-of-type(1) {
    margin-top: rem(-110);
    margin-bottom: rem(110);

    @include mq('md') {
        margin-top: 0;
        margin-bottom: 0;
    }
}
.p-business__cards li:nth-of-type(2) {
    margin-top: rem(-55);
    margin-bottom: rem(55);

    @include mq('md') {
        margin-top: rem(30);
    }
}
.p-business__cards li:nth-of-type(3) {
    @include mq('md') {
        margin-top: rem(30);
    }
}

.p-business__card {

}

