@charset "UTF-8";
html {
  font-size: 100%;
}
@media (max-width: 1200px) {
  html {
    font-size: 1.3333333333vw;
  }
}
@media screen and (max-width: 767px) {
  html {
    font-size: 100%;
  }
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}

body {
  font-family: noto-sans-cjk-jp, sans-serif;
  color: #DD1F22;
}

a,
button {
  transition: 0.25s;
}
a:hover,
button:hover {
  opacity: 0.5;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  a:hover,
  button:hover {
    opacity: 1;
  }
}

a {
  display: block;
}

img {
  width: 100%;
}

li {
  counter-increment: num;
}

/*****************************
* A Modern CSS Reset (https://github.com/hankchizljaw/modern-css-reset)
* 上記に、ul要素,ol要素,a要素への記述追加
*****************************/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
li {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  border: none;
  padding: 0;
  background-color: initial;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.l-inner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .l-inner {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
  }
}

.l-main {
  overflow: hidden;
}

.c-btn {
  display: inline-block;
  min-width: 8.875rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.08em;
  background: #fff;
  padding: 0.75rem;
  border-radius: 9.375rem;
  border: 1px solid #DD1F22;
  font-family: roboto-condensed, sans-serif;
  text-transform: uppercase;
}

.c-card__img img {
  display: block;
  aspect-ratio: 330/440;
  object-fit: cover;
  filter: drop-shadow(0.625rem 0.625rem 0 rgba(0, 0, 0, 0.16));
  will-change: filter;
}
@media screen and (max-width: 767px) {
  .c-card__img img {
    aspect-ratio: 330/204;
    object-fit: contain;
  }
}

.c-card__content a {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.c-card__content--b {
  display: flex;
  align-items: center;
  margin-top: 2.1875rem;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .c-card__content--b {
    margin-top: 1.5625rem;
    display: block;
  }
}

.c-card__body {
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .c-card__body {
    margin-top: 1.5625rem;
  }
}

.c-card__title {
  font-size: 0.75rem;
  line-height: 2;
  letter-spacing: 0.08em;
  font-family: roboto-condensed, sans-serif;
}
@media screen and (max-width: 767px) {
  .c-card__title {
    font-size: 0.625rem;
    line-height: 2.4;
  }
}

.c-card__title::after {
  content: counter(num, decimal-leading-zero);
}

.c-card__title span {
  padding: 0.1875rem;
}

.c-card__lead {
  margin-top: 0.625rem;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 2.7333333333;
}

.c-card__content--b-lead {
  margin-top: 0;
}

.c-card__btn {
  display: inline-block;
  vertical-align: middle;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  border: 1px solid #DD1F22;
  position: relative;
}
.c-card__btn::before, .c-card__btn::after {
  content: "";
  position: absolute;
  height: 0.0625rem;
  right: 0.9375rem;
  background: #DD1F22;
  transition: all 0.25s;
}
.c-card__btn::before {
  width: 0.4375rem;
  top: calc(50% - 0.0625rem);
  transform: translateY(calc(-50% - 0.0625rem)) rotate(30deg);
}
.c-card__btn::after {
  top: 50%;
  width: 1.125rem;
  transform: translateY(-50%);
}

.c-card2 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.c-card2__img {
  display: inline-block;
  text-align: center;
  align-items: center;
  min-height: 17.3125rem;
  padding: 6.875rem 3.3125rem;
  background: #fff;
  border-radius: 3.125rem;
  border: 0.015625rem solid #BFBFBF;
  box-shadow: 0.625rem 0.625rem 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 767px) {
  .c-card2__img {
    min-height: 12.6875rem;
    padding: 5rem 2.5rem;
  }
}

.c-card2__img img {
  width: 21.5625rem;
}
@media screen and (max-width: 767px) {
  .c-card2__img img {
    width: 20.625rem;
  }
}

.c-card2__img--2 {
  padding: 6.25rem 5.1875rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .c-card2__img--2 {
    padding: 4.5625rem 3.9375rem;
  }
}

.c-card2__img--2 img {
  width: 17.6875rem;
}
@media screen and (max-width: 767px) {
  .c-card2__img--2 img {
    width: 12.75rem;
  }
}

.c-card2__body {
  margin-top: 1.125rem;
}

.c-card2__title {
  font-size: 0.75rem;
  line-height: 2;
  letter-spacing: 0.08em;
  font-family: roboto-condensed, sans-serif;
}
@media screen and (max-width: 767px) {
  .c-card2__title {
    font-size: 10px;
    line-height: 2.4;
  }
}

.c-card2__title::after {
  content: counter(num, decimal-leading-zero);
}

.c-card2__title span {
  padding: 0.1875rem;
}

.c-card2__lead {
  margin-top: 0.625rem;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 2.7333333333;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .c-card2__lead {
    letter-spacing: 0;
    margin-top: 0;
  }
}

.c-card2__text {
  margin-top: 1.3125rem;
  margin-bottom: 1.625rem;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 2;
}
@media screen and (max-width: 767px) {
  .c-card2__text {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    line-height: 1.8666666667;
  }
}

.c-card2__btns {
  display: flex;
  flex-grow: 1;
}

.c-card2__btns--b {
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  padding-top: 0.9375rem;
  border-top: 0.0625rem solid #DD1F22;
  margin-top: auto;
  text-align: center;
  width: 100%;
}

.c-card2__btns a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.9375rem;
  border-top: 0.0625rem solid #DD1F22;
  margin-top: auto;
  text-align: center;
  width: 100%;
}

.c-card2__btn-text {
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 2;
}

.c-card2__btn {
  margin-left: 1.25rem;
}

.c-scrolldown {
  position: absolute;
  bottom: -5.625rem;
  right: 1.25rem;
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.08em;
  writing-mode: vertical-rl;
}
@media screen and (max-width: 1024px) {
  .c-scrolldown {
    bottom: -10rem;
    right: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-scrolldown {
    top: initial;
    bottom: 0;
    left: -18.125rem;
    margin-left: 0;
  }
}

.c-scrolldown span {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 0.625rem;
}

.c-scrolldown span:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: #DD1F22;
  animation: circlemove 1.6s ease-in-out infinite, cirlemovehide 1.6s ease-out infinite;
}

@keyframes circlemove {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}
@keyframes cirlemovehide {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
.c-scrolldown span:after {
  content: "";
  display: block;
  width: 0.0625rem;
  height: 3.75rem;
  background: #DD1F22;
}

.c-title {
  display: inline-block;
  text-align: center;
  font-size: 1.0625rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  padding: 1.25rem;
  border-radius: 9.375rem;
  background: #DD1F22;
  max-width: 15.3125rem;
}

.c-title--red {
  color: #DD1F22;
  background: #fff;
}

.c-title-red--wm {
  writing-mode: vertical-lr;
  text-orientation: upright;
}
@media screen and (max-width: 767px) {
  .c-title-red--wm {
    writing-mode: horizontal-tb;
    color: #fff;
    background: #DD1F22;
  }
}

.c-title-en {
  display: block;
  font-size: 0.75rem;
  line-height: 2;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: roboto-condensed, sans-serif;
}
.c-title-en::before {
  content: "";
  display: inline-block;
  width: 1.375rem;
  height: 1.0625rem;
  margin-right: 0.4375rem;
  background: url(../images/logo-min.svg) no-repeat center/cover;
  vertical-align: middle;
}

.c-title-en--white {
  color: #fff;
}
.c-title-en--white::before {
  background: url(../images/logo-min-white.svg) no-repeat center/cover;
}

.c-title-en-white--wm {
  display: inline-block;
  writing-mode: vertical-lr;
  vertical-align: top;
}
@media screen and (max-width: 767px) {
  .c-title-en-white--wm {
    writing-mode: horizontal-tb;
    vertical-align: middle;
    color: #DD1F22;
  }
}
.c-title-en-white--wm::before {
  margin-bottom: 0.625rem;
}
@media screen and (max-width: 767px) {
  .c-title-en-white--wm::before {
    margin-bottom: 0;
  }
}

.c-title-en--lg {
  display: inline-block;
  margin-right: 1.3125rem;
}

.p-business {
  padding-top: 12.5rem;
}
@media screen and (max-width: 1024px) {
  .p-business {
    padding-top: 6.25rem;
  }
}
@media screen and (max-width: 767px) {
  .p-business {
    padding-top: 5.625rem;
    margin-top: -5.625rem;
  }
}

.p-business__inner {
  position: relative;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}
.p-business__inner::before {
  content: "";
  width: 232.625rem;
  height: 232.625rem;
  bottom: -84.375rem;
  right: calc(50% + 300px);
  background: url(../images/section-bg@2x.png) no-repeat center/cover;
  z-index: -1;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .p-business__inner::before {
    right: calc(50% + 250px);
  }
}
@media screen and (max-width: 767px) {
  .p-business__inner::before {
    width: 74.25rem;
    height: 74.25rem;
    bottom: 27.5rem;
    right: calc(50% + 70px);
    background: url(../images/sec-bg-sp@2x.png) no-repeat center/cover;
  }
}
@media screen and (max-width: 767px) {
  .p-business__inner {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    max-width: 31.25rem;
  }
}

.p-business__title-box {
  text-align: right;
}

.p-business__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.125rem;
  margin-top: 3.4375rem;
}
@media screen and (max-width: 767px) {
  .p-business__cards {
    display: block;
    margin-top: 1.25rem;
  }
}

.p-business__cards li:nth-of-type(1) {
  margin-top: -6.875rem;
  margin-bottom: 6.875rem;
}
@media screen and (max-width: 767px) {
  .p-business__cards li:nth-of-type(1) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.p-business__cards li:nth-of-type(2) {
  margin-top: -3.4375rem;
  margin-bottom: 3.4375rem;
}
@media screen and (max-width: 767px) {
  .p-business__cards li:nth-of-type(2) {
    margin-top: 1.875rem;
  }
}

@media screen and (max-width: 767px) {
  .p-business__cards li:nth-of-type(3) {
    margin-top: 1.875rem;
  }
}

.p-company {
  padding-top: 13.75rem;
  padding-bottom: 13.75rem;
}
@media screen and (max-width: 767px) {
  .p-company {
    padding-top: 5.625rem;
    margin-top: -2.5rem;
    padding-bottom: 3.125rem;
  }
}

.p-company__inner {
  padding-left: 3.125rem;
  display: flex;
  align-items: center;
  position: relative;
}
.p-company__inner::before {
  content: "";
  width: 232.625rem;
  height: 232.625rem;
  bottom: -101.125rem;
  left: calc(50% + 300px);
  background: url(../images/section-bg@2x.png) no-repeat center/cover;
  z-index: -1;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .p-company__inner::before {
    left: calc(50% + 225px);
  }
}
@media screen and (max-width: 767px) {
  .p-company__inner::before {
    background: none;
  }
}
@media screen and (max-width: 767px) {
  .p-company__inner {
    padding-left: 1.125rem;
    flex-direction: column;
    align-items: start;
    position: relative;
    max-width: 31.25rem;
  }
}

.p-company__info {
  margin-left: 3.875rem;
  display: grid;
  grid-template-columns: 7.5rem 30rem;
}
@media screen and (max-width: 767px) {
  .p-company__info {
    margin-top: 1.25rem;
    margin-left: auto;
    grid-template-columns: 5.3125rem 15.9375rem;
  }
}

.p-company__title {
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 2.7333333333;
  padding: 1.25rem;
  border-bottom: 1px solid #DD1F22;
}
.p-company__title:first-child {
  border-top: 1px solid #DD1F22;
}
@media screen and (max-width: 767px) {
  .p-company__title {
    padding: 0.625rem 0;
    line-height: 2;
  }
}

.p-company__desc {
  font-size: 0.9375rem;
  line-height: 2.7333333333;
  padding: 1.25rem;
  border-bottom: 1px solid #F8CFCF;
}
.p-company__desc:nth-of-type(1) {
  border-top: 1px solid #F8CFCF;
}
@media screen and (max-width: 767px) {
  .p-company__desc {
    padding: 0.625rem;
    line-height: 2;
  }
}

.p-company__logo {
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .p-company__logo {
    position: absolute;
    top: 1.25rem;
    right: 1.125rem;
  }
}

.p-company__logo img {
  width: 11.0625rem;
  height: auto;
}
@media screen and (max-width: 767px) {
  .p-company__logo img {
    width: 5.625rem;
  }
}

.p-contact {
  padding-top: 6rem;
}
@media screen and (max-width: 767px) {
  .p-contact {
    padding-top: 3.125rem;
  }
}

@media screen and (max-width: 767px) {
  .p-contact__inner {
    padding-right: 1.75rem;
  }
}

.p-contact__img img {
  height: auto;
  filter: drop-shadow(0.625rem 0.625rem 0 rgba(0, 0, 0, 0.16));
  will-change: filter;
}

.p-contact_body {
  padding: 3.75rem 0;
  text-align: center;
  max-width: 68.75rem;
  border-radius: 3.75rem;
  background: #DD1F22;
  filter: drop-shadow(0.625rem 0.625rem 0 rgba(0, 0, 0, 0.16));
  margin: -6.875rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-contact_body {
    padding: 1.25rem 1.5625rem;
    border-radius: 1.25rem;
    margin-top: -3.125rem;
    width: 18rem;
  }
}

.p-contact__btn {
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .p-contact__btn {
    margin-top: 0;
  }
}

.p-contact__btn a {
  display: inline-block;
  background: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 0.85;
  padding: 1.25rem 16.25rem;
  border-radius: 4.375rem;
}
@media screen and (max-width: 767px) {
  .p-contact__btn a {
    font-size: 1rem;
    line-height: 1.0625;
    padding: 0.9375rem 1.25rem;
  }
}

@media screen and (max-width: 767px) {
  .p-contact__en-btn, .p-contact__btn {
    display: inline-block;
  }
}

.p-footer {
  padding: 9.375rem 3.5rem 3.125rem 3.125rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-footer {
    padding: 0 0 1.25rem 0;
    margin-top: 3.125rem;
  }
}
.p-footer::after {
  content: "";
  display: block;
  width: 27.675rem;
  height: 27.675rem;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: #DD1F22;
}
@media screen and (max-width: 767px) {
  .p-footer::after {
    width: 10.4375rem;
    height: 10.4375rem;
  }
}

.p-footer__inner {
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-footer__inner {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    max-width: 28.75rem;
    margin: 0 auto;
  }
}

.p-footer__nav {
  max-width: 40.3125rem;
}

.p-footer__nav ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav ul {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.25rem;
  }
}

.p-footer__nav-list a {
  padding: 1.25rem 0;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 2.9166666667;
  letter-spacing: 0.05em;
  min-width: 9.375rem;
  border-top: 1px solid #F8CFCF;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-list a {
    min-width: 10rem;
  }
}

address {
  font-style: normal;
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  address {
    margin-top: 0.9375rem;
  }
}

.p-footer__add {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.3846153846;
}

.p-footer__tel, .p-footer__fax {
  font-size: 1rem;
  line-height: 1.875;
  letter-spacing: 0.08em;
  font-family: roboto-condensed, sans-serif;
  display: inline-block;
  margin-top: 0.8125rem;
}
@media screen and (max-width: 767px) {
  .p-footer__tel, .p-footer__fax {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .p-footer__tel {
    margin-top: 0.9375rem;
  }
}

.p-footer__fax {
  margin-left: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .p-footer__fax {
    margin-left: 0;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-footer__privacy {
    margin-top: 0.9375rem;
  }
}

.p-footer__privacy a {
  display: inline-block;
}

.p-footer__privacy a img {
  width: 20.625rem;
  height: auto;
}
@media screen and (max-width: 767px) {
  .p-footer__privacy a img {
    width: 16.25rem;
  }
}

.p-footer__copyright {
  font-size: 0.75rem;
  line-height: 2;
  letter-spacing: 0.08em;
  font-family: roboto-condensed, sans-serif;
}
@media screen and (max-width: 767px) {
  .p-footer__copyright {
    margin-top: 0.9375rem;
    line-height: 1.5;
  }
}

.p-footer__logo {
  position: absolute;
  bottom: 3.9375rem;
  right: 3.5rem;
}
@media screen and (max-width: 767px) {
  .p-footer__logo {
    right: 1.3125rem;
    bottom: 1.5rem;
  }
}

.p-footer__logo img {
  width: 10.4375rem;
}
@media screen and (max-width: 767px) {
  .p-footer__logo img {
    width: 3.9375rem;
  }
}

.p-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 11.25rem;
  z-index: 3;
}
@media screen and (max-width: 767px) {
  .p-header {
    position: fixed;
    background: #DD1F22;
    height: 5.625rem;
  }
}

.p-header__inner {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-header__logo a img {
  width: 5.545rem;
  height: auto;
}
@media screen and (max-width: 767px) {
  .p-header__logo a img {
    width: 4.870625rem;
  }
}

.p-header__hamburger {
  display: none;
}
@media screen and (max-width: 767px) {
  .p-header__hamburger {
    display: block;
    position: fixed;
    top: 2.5rem;
    right: 1.0625rem;
    z-index: 1000;
    cursor: pointer;
    width: 1.875rem;
    height: 0.9375rem;
  }
}

/*×に変化*/
@media screen and (max-width: 767px) {
  .p-header__hamburger span {
    display: inline-block;
    position: absolute;
    right: 0;
    width: 1.875rem;
    height: 2px;
    background-color: #fff;
    transition: all 0.5s;
  }
}

.p-header__hamburger span:nth-of-type(1) {
  top: 0;
}

.p-header__hamburger span:nth-of-type(2) {
  top: 0.6875rem;
}

.p-header__hamburger.active span:nth-of-type(1) {
  top: 1.5rem;
  right: 2.5rem;
  width: 1.875rem;
  background-color: #fff;
  transform: translateY(0.375rem) rotate(-45deg);
}

.p-header__hamburger.active span:nth-of-type(2) {
  top: 2.25rem;
  right: 2.5rem;
  width: 1.875rem;
  background-color: #fff;
  transform: translateY(-0.375rem) rotate(45deg);
}

.p-header__hamburger span.js-headerColorScroll {
  background-color: #DD1F22;
}

@media screen and (max-width: 767px) {
  .p-header__nav {
    display: none;
    transition: all 0.3s;
    position: fixed;
    top: 1.5rem;
    left: 1.125rem;
    right: 1.125rem;
    height: 100dvh;
    z-index: 999;
  }
}

.p-header__nav-inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .p-header__nav-inner {
    display: block;
    text-align: center;
    padding: 3rem 1.5rem;
    background: #DD1F22;
    border-radius: 2.5rem;
  }
}

.p-header__nav-list {
  display: flex;
  padding-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .p-header__nav-list {
    display: block;
    padding-top: 0;
  }
}

.p-header__nav-item a {
  display: block;
  color: #fff;
  padding: 0.9375rem;
  text-align: center;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 2;
}
@media screen and (max-width: 767px) {
  .p-header__nav-item a {
    display: inline-block;
    font-size: 1.125rem;
    line-height: 1.6666666667;
    padding: 0.625rem;
  }
}
.p-header__nav-item a::after {
  content: attr(data-en);
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 2;
  letter-spacing: 0.08em;
  font-family: roboto-condensed, sans-serif;
  text-transform: uppercase;
}

.p-header__btns {
  margin-left: 4.875rem;
  margin-top: 3.4375rem;
}
@media screen and (max-width: 1024px) {
  .p-header__btns {
    margin-left: 3.125rem;
  }
}
@media screen and (max-width: 767px) {
  .p-header__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.0625rem;
    margin-left: 0;
  }
}

.p-header__btn:not(:first-child) {
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-header__btn:not(:first-child) {
    margin-top: 0;
    margin-left: 0.625rem;
  }
}

.p-header__tel-btn {
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .p-header__tel-btn {
    pointer-events: auto;
  }
}

.p-header__hamburger-Bg {
  display: none;
}
@media screen and (max-width: 767px) {
  .p-header__hamburger-Bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    z-index: 990;
    background: rgba(0, 0, 0, 0.4);
  }
}

.p-info {
  padding-top: 5rem;
}
@media screen and (max-width: 767px) {
  .p-info {
    margin-top: -2.5rem;
    padding-top: 5.625rem;
  }
}

.p-info__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}
@media screen and (max-width: 767px) {
  .p-info__inner {
    display: block;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
}

.p-info__title-box {
  width: 23.4359483615%;
}
@media screen and (max-width: 767px) {
  .p-info__title-box {
    min-width: 15rem;
    display: flex;
    align-items: center;
  }
}

.p-info__body {
  min-width: 45.625rem;
  margin-left: 2.5rem;
  padding-right: 2.5rem;
  width: 76.5640516385%;
}
@media screen and (max-width: 767px) {
  .p-info__body {
    width: 100%;
    margin-left: 0;
    margin-top: 1.25rem;
    padding-right: 0;
    min-width: initial;
  }
}

.p-info__list {
  display: flex;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #DD1F22;
}
@media screen and (max-width: 767px) {
  .p-info__list {
    display: block;
    padding-bottom: 0.9375rem;
  }
}

.p-info__date time {
  font-size: 0.75rem;
  line-height: 2.1666666667;
  font-family: roboto-condensed, sans-serif;
}

.p-info__text {
  font-size: 0.9375rem;
  line-height: 1.8666666667;
  margin-left: 1.875rem;
}
@media screen and (max-width: 767px) {
  .p-info__text {
    margin-left: 0;
  }
}

.p-MV {
  overflow: hidden;
  position: relative;
  padding-top: 11.25rem;
}
.p-MV::before {
  content: "";
  width: 175.3294289898vw;
  height: 175.3294289898vw;
  bottom: 20.5rem;
  right: -21.9619326501vw;
  background: url(../images/bg-red.png) no-repeat center/cover;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .p-MV::before {
    bottom: 34rem;
    right: -20.4978038067vw;
  }
}
@media screen and (max-width: 767px) {
  .p-MV::before {
    background: none;
  }
}
@media screen and (max-width: 767px) {
  .p-MV {
    position: relative;
    padding-top: 5.875rem;
  }
}

.p-MV__inner {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  display: flex;
  align-items: center;
  position: relative;
}
.p-MV__inner::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 33.125rem;
  right: 3.75rem;
  width: 111.25rem;
  height: 111.25rem;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .p-MV__inner::before {
    width: 301.6vw;
    height: 301.6vw;
    bottom: 7.5rem;
    right: -80vw;
    background: url(../images/bg-red.png) no-repeat center/cover;
    position: absolute;
  }
}
@media screen and (max-width: 767px) {
  .p-MV__inner {
    flex-direction: column;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
}

.p-MV__body {
  width: 64.5454545455%;
}
@media screen and (max-width: 767px) {
  .p-MV__body {
    width: 100%;
  }
}

.p-MV__title {
  font-size: 3.25rem;
  font-weight: 400;
  line-height: 1.6538461538;
  letter-spacing: 0.15em;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .p-MV__title {
    font-size: 1.875rem;
    line-height: 1.4666666667;
  }
}

.p-MV__lead {
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 2.6315789474;
  letter-spacing: 0.08em;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .p-MV__lead {
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 3.2222222222;
    letter-spacing: normal;
    overflow-wrap: break-word;
  }
}

.p-MV__lead span {
  font-size: 2.375rem;
  font-weight: 300;
  line-height: 2.6315789474;
  letter-spacing: 0.08em;
  font-family: roboto-condensed, sans-serif;
  text-transform: uppercase;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .p-MV__lead span {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 3.2222222222;
  }
}

.p-MV__text {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 2.4285714286;
  letter-spacing: 0.04em;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .p-MV__text {
    font-size: 0.8125rem;
    line-height: 2.0769230769;
  }
}

.p-MV__logo {
  width: 35.4545454545%;
  margin-left: 0.625rem;
  margin-bottom: 5.625rem;
}
@media screen and (max-width: 767px) {
  .p-MV__logo {
    width: 100%;
    margin-top: 37.3333333333vw;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.p-MV__logo img {
  width: 19.25rem;
  height: auto;
}
@media screen and (max-width: 767px) {
  .p-MV__logo img {
    margin-left: auto;
    width: 49.0666666667vw;
    height: auto;
  }
}

.p-MV__inner2 {
  margin-top: 9.375rem;
  position: relative;
  background: url(../images/MV@2x.png) no-repeat center/contain;
}
@media screen and (max-width: 767px) {
  .p-MV__inner2 {
    background: none;
    margin-top: 0;
    max-width: 31.25rem;
  }
}

.P-MV__content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media screen and (max-width: 767px) {
  .P-MV__content {
    flex-direction: column-reverse;
  }
}

.p-MV__body2 {
  width: 51.8333333333%;
  margin-left: 1.625rem;
}
@media screen and (max-width: 767px) {
  .p-MV__body2 {
    margin-top: 3.125rem;
    padding-top: 0;
    background: url(../images/MV@2x.png) no-repeat center/cover;
    border-radius: 2.5rem;
    width: 100%;
    margin-left: 0;
    padding: 3.125rem 0 12.5rem;
  }
}

.p-MV__example {
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.75;
  letter-spacing: 0.04em;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .p-MV__example {
    padding-left: 3.3125rem;
    font-size: 0.9375rem;
    line-height: 2.1333333333;
  }
}
.p-MV__example::before {
  content: "";
  display: inline-block;
  width: 1.9375rem;
  height: 1.5rem;
  margin-right: 0.625rem;
  background: url(../images/logo-min.svg) no-repeat center/cover;
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  .p-MV__example::before {
    width: 1.5625rem;
    height: 1.25rem;
  }
}

.p-MV__example-title {
  margin-top: 1.875rem;
  font-size: 3.125rem;
  font-weight: 300;
  line-height: 1.74;
  letter-spacing: 0.15em;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .p-MV__example-title {
    margin-top: 1.25rem;
    padding: 0 3rem;
    font-size: 1.625rem;
    line-height: 1.8846153846;
  }
}

.p-MV__example-text {
  margin-top: 1.875rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.75;
  letter-spacing: 0.04em;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .p-MV__example-text {
    margin-top: 1.25rem;
    padding: 0 1.875rem;
    font-size: 0.9375rem;
    line-height: 2.1333333333;
  }
}

.p-MV__img {
  position: relative;
  z-index: 2;
  width: 48.1666666667%;
}
@media screen and (max-width: 767px) {
  .p-MV__img {
    margin-top: -12.5rem;
    width: 100%;
  }
}

.p-MV__img img {
  aspect-ratio: 992/1444;
  object-fit: contain;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-MV__img img {
    aspect-ratio: 240/390;
    width: 15rem;
    margin: 0 auto;
    height: 100%;
  }
}

.p-MV__copyright {
  font-size: 10px;
  line-height: 2.4;
  letter-spacing: 0.08em;
  color: #fff;
  writing-mode: vertical-rl;
  text-transform: capitalize;
  position: absolute;
  top: 20.3125rem;
  left: 1.5625rem;
}
@media screen and (max-width: 1024px) {
  .p-MV__copyright {
    left: 1.125rem;
  }
}
@media screen and (max-width: 767px) {
  .p-MV__copyright {
    top: 9.375rem;
    left: 0;
    right: 0.1875rem;
  }
}

.p-partners {
  padding-bottom: 10rem;
  padding-top: 5rem;
}
@media screen and (max-width: 767px) {
  .p-partners {
    padding-bottom: 0;
    margin-top: -2.5rem;
    padding-top: 5.625rem;
  }
}

.p-partners__inner {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: end;
  position: relative;
}
.p-partners__inner::before {
  content: "";
  width: 232.625rem;
  height: 232.625rem;
  bottom: -106.875rem;
  left: calc(50% + 300px);
  background: url(../images/section-bg@2x.png) no-repeat center/cover;
  z-index: -1;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .p-partners__inner::before {
    left: calc(50% + 225px);
  }
}
@media screen and (max-width: 767px) {
  .p-partners__inner::before {
    background: none;
  }
}
@media screen and (max-width: 767px) {
  .p-partners__inner {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    flex-direction: column;
    max-width: 37.5rem;
  }
}

.p-partners__title-box {
  width: 8.5454545455%;
  display: flex;
}
@media screen and (max-width: 767px) {
  .p-partners__title-box {
    width: 100%;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .p-partners__en-title::before {
    content: "";
    display: inline-block;
    width: 1.375rem;
    height: 1.0625rem;
    margin-right: 0.4375rem;
    background: url(../images/logo-min.svg) no-repeat center/cover;
    vertical-align: middle;
  }
}

.p-partners__logos {
  width: 91.4545454545%;
}
@media screen and (max-width: 767px) {
  .p-partners__logos {
    margin-top: 1.25rem;
    width: 100%;
  }
}

.p-partners__logos img {
  width: 51.4375rem;
  height: auto;
}
@media screen and (max-width: 767px) {
  .p-partners__logos img {
    width: 100%;
  }
}

.p-recruit__inner {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  position: relative;
}
.p-recruit__inner::before {
  content: "";
  width: 232.625rem;
  height: 232.625rem;
  bottom: -87rem;
  right: calc(50% + 300px);
  background: url(../images/section-bg@2x.png) no-repeat center/cover;
  z-index: -1;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .p-recruit__inner::before {
    right: calc(50% + 220px);
  }
}
@media screen and (max-width: 767px) {
  .p-recruit__inner::before {
    width: 74.25rem;
    height: 74.25rem;
    bottom: 15rem;
    right: calc(50% + 70px);
    background: url(../images/sec-bg-sp@2x.png) no-repeat center/cover;
  }
}
@media screen and (max-width: 767px) {
  .p-recruit__inner {
    padding-left: 0;
    padding-right: 0;
    max-width: 34.375rem;
    margin: 0 auto;
  }
}

.p-recruit__img img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0.625rem 0.625rem 0 rgba(0, 0, 0, 0.16));
  will-change: filter;
}

.p-recruit__body {
  display: flex;
  margin-top: 0.625rem;
  padding-top: 6.875rem;
}
@media screen and (max-width: 767px) {
  .p-recruit__body {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: -4rem;
    padding-top: 5.625rem;
    position: relative;
  }
}

.p-recruit__title-box {
  padding-top: 5.625rem;
}
@media screen and (max-width: 767px) {
  .p-recruit__title-box {
    position: absolute;
    top: 7rem;
    left: 1.25rem;
  }
}

.p-recruit__lead {
  font-size: 3rem;
  line-height: 1.875;
  writing-mode: vertical-rl;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .p-recruit__lead {
    font-size: 2rem;
    line-height: 1.71875;
  }
}

.p-recruit__content {
  margin-left: 5rem;
  margin-right: 3.125rem;
}
@media screen and (max-width: 767px) {
  .p-recruit__content {
    margin-left: 0;
    margin-right: 0;
  }
}

.p-recruit__text {
  font-size: 1rem;
  line-height: 2.5;
}
@media screen and (max-width: 767px) {
  .p-recruit__text {
    font-size: 0.9375rem;
    line-height: 1.8666666667;
    margin-top: 1.25rem;
  }
}

.p-recruit__sub-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2.05;
  padding-top: 2.5rem;
  padding-bottom: 1.875rem;
  border-bottom: 0.1875rem solid #DD1F22;
}
@media screen and (max-width: 767px) {
  .p-recruit__sub-title {
    padding-top: 1.625rem;
    padding-bottom: 1.25rem;
  }
}

.p-recruit__btn a {
  padding: 1.5625rem 0;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 2.7333333333;
  border-bottom: 1px solid #DD1F22;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .p-recruit__btn a {
    padding: 2.125rem 0;
  }
}

.p-recruit__btn--item {
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .p-recruit__title-sp {
    color: #fff;
    background: #DD1F22;
  }
}

@media screen and (max-width: 767px) {
  .p-recruit__en-title-sp {
    color: #DD1F22;
  }
  .p-recruit__en-title-sp::before {
    content: "";
    display: inline-block;
    width: 1.375rem;
    height: 1.0625rem;
    margin-right: 0.4375rem;
    background: url(../images/logo-min.svg) no-repeat center/cover;
    vertical-align: middle;
  }
}

.p-service {
  padding-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .p-service {
    padding-top: 5.625rem;
  }
}

.p-service__inner {
  padding-left: 7.8125rem;
  padding-right: 7.8125rem;
  position: relative;
}
.p-service__inner::before {
  content: "";
  width: 232.625rem;
  height: 232.625rem;
  bottom: -78rem;
  right: calc(50% + 310px);
  background: url(../images/section-bg@2x.png) no-repeat center/cover;
  z-index: -1;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .p-service__inner::before {
    right: calc(50% + 260px);
  }
}
@media screen and (max-width: 767px) {
  .p-service__inner::before {
    width: 74.25rem;
    height: 74.25rem;
    bottom: 25.625rem;
    right: calc(50% + 70px);
    background: url(../images/sec-bg-sp@2x.png) no-repeat center/cover;
  }
}
@media screen and (max-width: 767px) {
  .p-service__inner {
    padding-left: 0;
    padding-right: 0;
    max-width: 21.25rem;
  }
}

.p-service__title-box {
  text-align: right;
}

.p-service__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3.125rem;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .p-service__cards {
    display: block;
    margin-top: 0;
  }
}

.p-service__card:nth-of-type(1) {
  margin-top: -5.625rem;
  margin-bottom: 5.625rem;
}
@media screen and (max-width: 767px) {
  .p-service__card:nth-of-type(1) {
    margin-top: 1.25rem;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-service__card:nth-of-type(2) {
    margin-top: 1.25rem;
  }
}

@media screen and (max-width: 767px) {
  .u-desktop {
    display: none;
  }
}

.u-dots {
  background-image: radial-gradient(circle at center, #fff 16%, transparent 16%); /* 点の色とサイズ調整 */
  background-position: top right; /* 点の位置 */
  background-repeat: repeat-x; /* 横方向に繰り返し */
  background-size: 1.18em 0.2em; /* 点の間隔とサイズ調整 */
  padding-top: 0.2em; /* 縦方向の位置調整 */
}

.u-mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .u-mobile {
    display: block;
  }
}