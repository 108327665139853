@use "global" as *;

// インナー幅＋余白
$breakpointInner: $innerWidth + strip-unit($padding-pc) * 2;

// ルートフォント設定
html {
  // インナー幅＋余白+1px ~
  font-size: 100%;

  // 768px ~ インナー幅＋余白
  @media ( max-width: $breakpointInner ) {
    font-size: vw(strip-unit($breakpointInner), 16);
  }

  // 376px ~ 767px
  @include mq(md) {
    font-size: 100%;
  }

  // ~ 375px
  @media (max-width: 375px) {
    font-size: vw(375, 16);
  }
}

// フォント、カラー設定
body {
  font-family: $font-main;
  color: $red;
}

// ホバー
a,
button {
  transition: .25s;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
    @include mq(md) {
      opacity: 1;
    }
  }
}
a {
  display: block;
}
img {
  width: 100%;
}
li {
  counter-increment: num;
}