@use "global" as *;

.c-title {
    display: inline-block;
    text-align: center;
    font-size: rem(17);
    font-weight: 700;
    line-height: 1;
    color: $white;
    padding: rem(20);
    border-radius: rem(150);
    background: $red;
    max-width: rem(245);
}
.c-title--red {
    color: $red;
    background: $white;
}
.c-title-red--wm {
    writing-mode: vertical-lr;
    text-orientation: upright;

    @include mq('md') {
        writing-mode: horizontal-tb;
        color: $white;
        background: $red;
    }
}

.c-title-en {
    display: block;
    font-size: rem(12);
    line-height: calc( 24 / 12 );
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-family: $font-en;

    &::before {
        content: "";
        display: inline-block;
        width: rem(22);
        height: rem(17);
        margin-right: rem(7);
        background: url(../images/logo-min.svg) no-repeat center / cover;
        vertical-align: middle;
    }
}
.c-title-en--white {
    color: $white;

    &::before {
        background: url(../images/logo-min-white.svg) no-repeat center / cover;
    }
}
.c-title-en-white--wm {
    display: inline-block;
    writing-mode: vertical-lr;
    vertical-align: top;

    @include mq('md') {
        writing-mode: horizontal-tb;
        vertical-align: middle;
        color: $red;
    }

    &::before {
        margin-bottom: rem(10);

        @include mq('md') {
            margin-bottom: 0;
        }
    }
}
.c-title-en--lg {
    display: inline-block;
    margin-right: rem(21);
}
