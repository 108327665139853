@use "global" as *;

.p-contact {
    padding-top: rem(96);

    @include mq('md') {
        padding-top: rem(50);
    }
}

.p-contact__inner {
    @include mq('md') {
        padding-right: rem(28);
    }
}


.p-contact__img img {
    height: auto;
    filter: drop-shadow(rem(10) rem(10) 0 rgba(0, 0, 0, 0.16));
    will-change: filter;
}

.p-contact_body {
    padding: rem(60) 0;
    text-align: center;
    max-width: rem(1100);
    border-radius: rem(60);
    background: $red;
    filter: drop-shadow(rem(10) rem(10) 0 rgba(0, 0, 0, 0.16));
    margin: rem(-110) auto 0;

    @include mq('md') {
        padding: rem(20) rem(25);
        border-radius: rem(20);
        margin-top: rem(-50);
        width: rem(288);
    }
}


.p-contact__btn {
    margin-top: rem(20);

    @include mq('md') {
        margin-top: 0;
    }
}
.p-contact__btn a {
    display: inline-block;
    background: $white;
    font-size: rem(20);
    font-weight: 700;
    line-height: calc(17 / 20);
    padding: rem(20) rem(260);
    border-radius: rem(70);

    @include mq('md') {
        font-size: rem(16);
        line-height: calc(17 / 16);
        padding: rem(15) rem(20);
    }
}

.p-contact__en-btn, .p-contact__btn {
    @include mq('md') {
        display: inline-block;
    }
}