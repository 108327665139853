@use "global" as *;

.c-scrolldown {
    position: absolute;
    bottom: rem(-90);
    right: rem(20);
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.08em;
    writing-mode: vertical-rl;

    @include mq('lg') {
        bottom: rem(-160);
        right: rem(40);
    }

    @include mq('md') {
        top: initial;
        bottom: 0;
        left: rem(-290);
        margin-left: 0;
    }
}
.c-scrolldown span {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: rem(10);
}

.c-scrolldown span:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: rem(10);
    height: rem(10);
    border-radius: 50%;
    background: $red;
    animation: circlemove 1.6s ease-in-out infinite, cirlemovehide 1.6s ease-out infinite;
}
@keyframes circlemove {
    0% {
        top: 0;
    }

    100% {
        top: 100%;
    }
}

@keyframes cirlemovehide {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    80% {
        opacity: 0.9;
    }

    100% {
        opacity: 0;
    }
}

.c-scrolldown span:after {
    content: "";
    display: block;
    width: rem(1);
    height: rem(60);
    background: $red;
}


