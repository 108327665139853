@use "global" as *;

.p-info {
    padding-top: rem(80);

    @include mq('md') {
        margin-top: rem(-40);
        padding-top: rem(90);
    }
}

.p-info__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: rem(50);
    padding-right: rem(50);

    @include mq('md') {
        display: block;
        padding-left: rem(18);
        padding-right: rem(18);
    }
}

.p-info__title-box {
    width: calc(236 / 1007 * 100%);

    @include mq('md') {
        min-width: rem(240);
        display: flex;
        align-items: center;
    }
}
.p-info__body {
    min-width: rem(730);
    margin-left: rem(40);
    padding-right: rem(40);
    width: calc(771 / 1007 * 100%);

    @include mq('md') {
        width: 100%;
        margin-left: 0;
        margin-top: rem(20);
        padding-right: 0;
        min-width: initial;
    }
}
.p-info__list {
    display: flex;
    align-items: flex-start;
    padding-top: rem(16);
    padding-bottom: rem(20);
    border-bottom: 1px solid $red;

    @include mq('md') {
        display: block;
        padding-bottom: rem(15);
    }
}
.p-info__date time {
    font-size: rem(12);
    line-height: calc(26 / 12);
    font-family: $font-en;
}

.p-info__text {
    font-size: rem(15);
    line-height: calc(28 / 15);
    margin-left: rem(30);

    @include mq('md') {
        margin-left: 0;
    }
}

