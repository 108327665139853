@use "global" as *;

.p-partners {
    padding-bottom: rem(160);
    padding-top: rem(80);

    @include mq('md') {
        padding-bottom: 0;
        margin-top: rem(-40);
        padding-top: rem(90);
    }
}

.p-partners__inner {
    padding-left: rem(50);
    padding-right: rem(50);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: end;
    position: relative;

    &::before {
        content: "";
        width: rem(3722);
        height: rem(3722);
        bottom: rem(-1710);
        left: calc(50% + 300px);
        background: url(../images/section-bg@2x.png) no-repeat center / cover;
        z-index: -1;
        position: absolute;

        @include mq('lg') {
            left: calc(50% + 225px);
        }

        @include mq('md') {
            background: none;
        }
    }

    @include mq('md') {
        padding-left: rem(18);
        padding-right: rem(18);
        flex-direction: column;
        max-width: rem(600);
    }
}

.p-partners__title-box {
    width: calc(94 / 1100 * 100%);
    display: flex;

    @include mq('md') {
        width: 100%;
        flex-direction: column;
    }
}
.p-partners__en-title {
    @include mq('md') {
        &::before {
            content: "";
            display: inline-block;
            width: rem(22);
            height: rem(17);
            margin-right: rem(7);
            background: url(../images/logo-min.svg) no-repeat center / cover;
            vertical-align: middle;
        }
    }
}

.p-partners__logos {
    width: calc(1006 / 1100 * 100%);

    @include mq('md') {
        margin-top: rem(20);
        width: 100%;
    }
}
.p-partners__logos img {
    width: rem(823);
    height: auto;


    @include mq('md') {
        width: 100%;
    }
}

