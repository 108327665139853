@use "global" as *;

.c-btn {
    display: inline-block;
    min-width: rem(142);
    text-align: center;
    font-size: rem(16);
    line-height: calc(24 / 16);
    letter-spacing: 0.08em;
    background: $white;
    padding: rem(12);
    border-radius: rem(150);
    border: 1px solid $red;
    font-family: $font-en;
    text-transform: uppercase;
}
